<template>
	<div 
		id="modal-rrss"
		class="modal" 
		tabindex="-1"
	>
		<div class="modal-dialog">
			<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title text-secondary">
					<i class="fa-solid fa-rss color-secondary me-1"></i>
					Configurar <strong>noticias</strong>
				</h5>
				<button 
					ref="closeModal"
					type="button" 
					class="modal-custom-close"
					data-bs-dismiss="modal" 
					aria-label="Close">
					<i class="fa-solid fa-xmark"></i>
				</button>
			</div>
			<div class="modal-body">
				<div class="card border-0 card-shadow">
					<div class="card-body">
						<div class="row">
							<div class="col-12 col-lg-12">
								<v-select 
									class="selvue-custom"
									:placeholder="'Seleccionar sitio de noticias'" 
									:options="apis"
									:getOptionLabel="api => api.nombre_rrss"
									v-model="selected_api" 
									:clearable="false"
								>
								</v-select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button 
					type="button" 
					ref="closeModal"
					class="btn btn-custom-color-white rounded-pill mw-100" 
					data-bs-dismiss="modal">
					<i class="fa-solid fa-angle-left pe-1"></i>
					Volver
				</button>
				<button 
					@click="saveApiNews()"
					type="button" 
					class="btn btn-custom-color-blue rounded-pill mw-100"
					:disabled="!selected_api"
				>
					<span> Añadir </span>
				</button>
			</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	props: {
		apis: {
			type: Array,
			required: true
		},
		loaded_apis: {
			type: Array
		}
	},

	data() {
		return {
			selected_api: "",
		}
	},
	
  watch: {
    loaded_apis(newVal) {
			if (newVal.length !== 0 && this.selected_api === "") {
        this.selected_api = newVal[0];
      }
    },
  },

  created() {
    if (this.loaded_apis) {
      // eslint-disable-next-line no-undef
      this.selected_api = structuredClone(this.loaded_apis[0]);
    }
  },

	methods: {
    ...mapActions("tvCorporativaModule", ["setChannelLayoutApiNews"]),
		...mapState("tvCorporativaModule", ["channelLayout"]),

		saveApiNews() {
			this.setChannelLayoutApiNews([this.selected_api]);
			this.$emit("addedApiNewsToChannel");
		},
		closeModal() { 
			this.$refs.closeModal.click();
		},

	}
}
</script>